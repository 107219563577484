import React from 'react';
import '../styles/inhub-initiatives.css';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { FullBtn, BorderBtn } from './home-buttons';

const InhubInitiatives = () => {
  const { t } = useTranslation();

  return (
    <>
      <h2 className="container initiatives-header">{t`home.projects-title`}</h2>

      <div className="initiative-ctn container">
        <div className="medbiz-initiative-help-ctn" />
        <div className="initiative-media-ctn medbiz-media-ctn">
          <StaticImage
            src="../../../images/home/inhub-initiatives/mentor1.jpg"
            alt="MedBiz initiative 1"
            placeholder="blurred"
            loading="lazy"
            quality={100}
            className="initiative-img medbiz-img-1"
          />
          <div className="medbiz-images-ctn">
            <StaticImage
              src="../../../images/home/inhub-initiatives/mentor2.jpg"
              alt="MedBiz initiative 2"
              placeholder="blurred"
              loading="lazy"
              quality={100}
              className="initiative-img medbiz-img-3"
            />
            <StaticImage
              src="../../../images/home/inhub-initiatives/mentor3.jpg"
              alt="MedBiz initiative 3"
              placeholder="blurred"
              loading="lazy"
              quality={100}
              className="initiative-img medbiz-img-2"
            />
          </div>
          <div className="medbiz-images-ctn">
            <StaticImage
              src="../../../images/home/inhub-initiatives/mentor4.jpg"
              alt="MedBiz initiative 4"
              placeholder="blurred"
              loading="lazy"
              quality={100}
              className="initiative-img medbiz-img-4"
            />
            <StaticImage
              src="../../../images/home/inhub-initiatives/mentor5.jpg"
              alt="MedBiz initiative 5"
              placeholder="blurred"
              loading="lazy"
              quality={100}
              className="initiative-img medbiz-img-5"
            />
          </div>
          <StaticImage
            src="../../../images/home/inhub-initiatives/mentor6.jpg"
            alt="MedBiz initiative 6"
            placeholder="blurred"
            loading="lazy"
            quality={100}
            className="initiative-img medbiz-img-6"
          />
        </div>

        <div className="initiative-text-ctn medbiz-initiative-text-ctn">
          <div className="mr-1">
            <StaticImage
              src="../../../images/mentoring/mentoring-image.svg"
              alt="Logo Mentoring Program"
              placeholder="none"
              loading="eager"
              quality={100}
              className="initiative-logo mb-1_2rem"
            />
          </div>
          <div className="logos-ctn">
            <div className="mr-1">
              <StaticImage
                src="../../../images/home/inhub-initiative-logos/medbiz_logo_white_powered_by.png"
                alt="Logo Innovations Academy"
                placeholder="none"
                loading="eager"
                quality={100}
                className="initiative-logo mb-1_2rem"
              />
            </div>
            <div className="mr-1">
              <StaticImage
                src="../../../images/home/inhub-initiative-logos/IGC_logo_white_powered_by.png"
                alt="Logo Innovations Academy"
                placeholder="none"
                loading="eager"
                quality={100}
                className="initiative-logo mb-1_2rem"
              />
            </div>
            <div className="mr-1">
              <StaticImage
                src="../../../images/home/inhub-initiative-logos/innovateit_white.png"
                alt="Logo Innovations Academy"
                placeholder="none"
                loading="eager"
                quality={100}
                className="initiative-logo mb-1_2rem"
              />
            </div>
          </div>
          <h4 className="mb-1rem">{t`home.medbiz-title`}</h4>
          <p className="mb-1rem">{t`home.medbiz-text`}</p>
          <div className="initiative-btn-ctn">
            <FullBtn to="/program-inkubacyjny">{t`home.medbiz`}</FullBtn>
            {/* <div className="green-btn">
            <FullBtn to="/green-innovations-challenge">
              {t`home.green`}
            </FullBtn>
            </div> */}
          </div>
        </div>
      </div>

      <div className="initiative-ctn container">
        <StaticImage
          className="circle-motive-incubator"
          src="../../../images/home/theme-circles/circle-elements-incubator-iniatives.png"
          alt="Circle motive incubator"
          placeholder="none"
          loading="lazy"
        />

        <div className="initiative-media-ctn incubator-media-ctn">
          <StaticImage
            src="../../../images/home/inhub-initiatives/incubator1.jpg"
            alt="Incubator initiative 1"
            placeholder="blurred"
            loading="lazy"
            quality={100}
            style={{ alignSelf: 'start' }}
            className="initiative-img incubator-img-1"
          />

          <StaticImage
            src="../../../images/home/inhub-initiatives/incubator2.jpg"
            alt="Incubator initiative 2"
            placeholder="blurred"
            loading="lazy"
            quality={100}
            className="initiative-img incubator-img-2"
          />
        </div>

        <div className="initiative-text-ctn">
          <StaticImage
            style={{ width: '160px' }}
            src="../../../images/home/inhub-initiative-logos/logo_incubator_light.png"
            alt="Logo Incubator Innovations"
            placeholder="none"
            loading="eager"
            quality={100}
            className="initiative-logo mb-1_2rem"
          />
          <h4 className="mb-1rem">{t`home.incubator-title`}</h4>
          <p className="mb-1rem">{t`home.incubator-text`}</p>
          <div className="initiative-btn-ctn">
            <BorderBtn to="/incubator">{t`home.check-out-offer`}</BorderBtn>
          </div>
        </div>
      </div>

      <div className="initiative-ctn container">
        <StaticImage
          className="circle-motive-academy"
          src="../../../images/home/theme-circles/circle-elements-academy-initiative.png"
          alt="Circle motive academy"
          placeholder="none"
          loading="lazy"
        />

        <div className="initiative-media-ctn">
          <StaticImage
            src="../../../images/home/inhub-initiatives/durka.jpg"
            alt="Academy initiative"
            placeholder="blurred"
            loading="lazy"
            quality={100}
            className="initiative-img academy-img"
          />
        </div>

        <div className="initiative-text-ctn">
          <StaticImage
            src="../../../images/home/inhub-initiative-logos/logo_academy_light.png"
            alt="Logo Academy Innovations"
            placeholder="none"
            loading="eager"
            quality={100}
            className="initiative-logo mb-1_2rem"
          />
          <h4 className="mb-1rem">{t`home.academy-title`}</h4>
          <p className="mb-1rem">{t`home.academy-text`}</p>
          <div className="initiative-btn-ctn">
            <BorderBtn to="/news">{t`home.read-more`}</BorderBtn>
          </div>
        </div>
      </div>

      <div className="initiative-ctn container">
        <StaticImage
          className="circle-motive-platform"
          src="../../../images/home/theme-circles/circle-elements-platform-initiatives.png"
          alt="Circle motive platform"
          placeholder="none"
          loading="lazy"
        />

        <div className="initiative-media-ctn">
          <StaticImage
            src="../../../images/home/inhub-initiatives/platform.png"
            alt="Platform initiative desktops"
            placeholder="none"
            loading="lazy"
            quality={100}
            className="platform-img"
          />
        </div>

        <div className="initiative-text-ctn">
          <StaticImage
            src="../../../images/home/inhub-initiative-logos/logo_platform_light.png"
            alt="Logo Platform Innovations"
            placeholder="none"
            loading="eager"
            quality={100}
            className="initiative-logo mb-1_2rem"
          />
          <h4 className="mb-1rem">{t`home.platform-title`}</h4>
          <p className="mb-1rem">{t`home.platform-text`}</p>
          <div className="initiative-btn-ctn">
            <FullBtn to="/registration">{t`home.create-account`}</FullBtn>
            <BorderBtn to="/registration">{t`home.learn-more`}</BorderBtn>
          </div>
        </div>
      </div>

      <div className="initiative-ctn container">
        <StaticImage
          className="circle-motive-ami"
          src="../../../images/home/theme-circles/circle-elements-incubator-iniatives.png"
          alt="Circle motive incubator"
          placeholder="none"
          loading="lazy"
        />

        <div className="initiative-media-ctn incubator-media-ctn">
          <StaticImage
            src="../../../images/home/inhub-initiatives/ami3.png"
            alt="Akademia Młodego Innowatora zdjęcie z gali"
            placeholder="blurred"
            loading="lazy"
            quality={100}
            className="initiative-img incubator-img-2"
          />

          <StaticImage
            src="../../../images/home/inhub-initiatives/ami2.png"
            alt="Akademia Młodego Innowatora zdjęcie z gali"
            placeholder="blurred"
            loading="lazy"
            quality={100}
            style={{ alignSelf: 'start' }}
            className="initiative-img incubator-img-1"
          />
        </div>

        <div className="initiative-text-ctn">
          <StaticImage
            style={{ width: '200px' }}
            src="../../../images/home/inhub-initiative-logos/AMI_logo_white.png"
            alt="Logo Akademii Młodego Innowatora"
            placeholder="none"
            loading="eager"
            quality={100}
            className="initiative-logo mb-1_2rem"
          />
          <h4 className="mb-1rem">{t`home.ami-title`}</h4>
          <p className="mb-1rem">{t`home.ami-text`}</p>
          <div className="initiative-btn-ctn">
            {/* <FullBtn to="https://forms.gle/7Y8UovBtBzPV8g1V8"> {t`home.sign-up`} </FullBtn> */}
            <BorderBtn to="/news/workshops">{t`home.learn-more`}</BorderBtn>
          </div>
        </div>
      </div>
    </>
  );
};

export default InhubInitiatives;
