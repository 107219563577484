import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { respondTo } from "../../../utils/breakpoints";
import styled from "styled-components";

export const MentoringModalOverlay = styled.div`
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  padding: 16px;
  background: rgba(15, 83, 115, 0.20);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  ${respondTo.m`
  padding: 32px;
    `
  }
`;

export const MentoringModalWrapper = styled.div`
  z-index: 100;
  width: 100%;
  border-radius: 10px;
  height: 600px;
  background: #FFF;
  color: #000;
  box-shadow: 0px 0px 20px 0px rgba(15, 83, 115, 0.20);
  box-sizing: border-box;
  ${respondTo.m`
    width: 1028px;
    max-height: 909px;
    height: 100%; 
    border-radius: 10px;
  `}
`

export const MentoringModalContainer = styled.div`
  width: 100%;
  border-radius: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  background: transparent;
`

export const MentoringModalContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  gap: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 60px 20px;
  ${respondTo.m`
  padding: 60px 32px;
  `}
`

export const MentoringModalCloseBtn = styled.button`
  position: absolute;
  top: 14px;
  right: 14px;
  background: transparent;
  border: none;
  cursor: pointer !important;
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  &:hover {
    > svg {
      stroke: #0F5575;
    }
  }
`;

export const MentoringModalImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 10px;
  align-self: flex-end;
  justify-self: flex-end;
`

export const MentoringModalHeader = styled.h2`
  margin: 0;
  text-align: center;
  font-style: normal;
  font-size: 28px !important;
  font-weight: 600;
  line-height: 150%; /* 54px */
  letter-spacing: -0.72px;
  > b {
    color: #4EBFEE;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 150%;
    letter-spacing: -0.72px;
  }
  > span {
    color: var(--UI-Black, #080E14);
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
  }
  ${respondTo.m`
    font-size: 36px !important;
    > span {
      font-size: 24px;
    }
    > b {
      font-size: 36px;
    }
  `}
`;

export const MentoringModalDescription = styled.p`
  color: rgba(0, 0, 0, 0.60);
  text-align: center;
  font-family: 'Raleway';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; 
  letter-spacing: -0.36px;
  ${respondTo.m`
    margin: 0px 99px;
  `}
`;

export const MentoringModalButtonReadMore = styled(Link)`
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
  letter-spacing: -0.32px;
  display: flex;
  height: 44px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #0F5575;
  transition: all 0.3s ease;
  border: 2px solid #0F5575;
  &:hover {
    background: white;
    color: #0F5575;
  }
`;